<template>
  <div>
    <div class="relative h-96" key="home">
      <div class="absolute inset-0">
        <img class="w-full h-full object-cover" :src="headerImage.url" :alt="`Header image of ${headerImage.name}`" />
        <div class="absolute inset-0 bg-indigo-400 mix-blend-multiply" aria-hidden="true" />
        <div class="absolute left-7 right-7 bottom-4">
          <div class="max-w-5xl mx-auto">
            <h3 class="text-base font-medium tracking-tight text-white">{{ headerImage.name }} ({{ headerImage.company }})
            </h3>
            <div class="flex flex-row text-white">
              <font-awesome-icon icon="fa-solid fa-camera" />
              <h4 class="ml-2 text-sm font-medium text-white">{{ headerImage.photographer }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div
        class="relative flex flex-col items-center text-center max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 class="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">Welcome to Showdeck</h1>
        <p class="mt-6 text-xl text-indigo-100 max-w-3xl">A platform for your production</p>
      </div>
    </div>
    <div>
      <div>
        <PreviewRow title="People" :previewItems="people" :roundedImages="true">
          <font-awesome-icon icon="fa-solid fa-user" />
        </PreviewRow>
      </div>
      <PreviewRow title="Places" :previewItems="places" :roundedImages="true">
        <font-awesome-icon icon="fa-solid fa-building" />
      </PreviewRow>
      <PreviewRow title="Things" :previewItems="temp" :roundedImages="true">
        <font-awesome-icon icon="fa-solid fa-box-open" />
      </PreviewRow>
      <PreviewRow title="Productions" :previewItems="productions" :roundedImages="true">
        <font-awesome-icon icon="fa-solid fa-masks-theater" />
      </PreviewRow>
      <PreviewRow title="Companies" :previewItems="companies" :roundedImages="true">
        <font-awesome-icon icon="fa-solid fa-users" />
      </PreviewRow>
      <PreviewRow title="Works" :previewItems="temp" :roundedImages="true">
        <font-awesome-icon icon="fa-solid fa-scroll" />
      </PreviewRow>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import PreviewRow from "../components/widgets/previewRow/PreviewRow.vue";
import { useStore } from "vuex";


const temp = ref([]);

const headerImage = ref({
  url: "https://images.unsplash.com/photo-1579539760267-b2e78d9d735e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  name: "Shakespere on Ice",
  company: "Company",
  photographer: "Jón Jónsson"
})


export default {
  name: 'Home',
  components: {
    PreviewRow,
  },
  setup() {
    const store = useStore();
    const router = useRouter()

    store.dispatch('getBasicVenues')
    const places = computed(() => {
      return store.getters.getBasicVenues.map(item => {
        const go = () => router.push({ name: 'Venue', params: { id: item.id } })
        return { title: item.name, subtitle: item.venueType, image: item.image, go }
      }).slice(0, 5)
    })

    store.dispatch('fetchRandomPeople', 5)
    const people = computed(() => {
      return store.getters.getTableFormattedPeople.map(item => {
        const go = () => router.push({ name: 'Profile', params: { id: item.id } })
        return { title: item.name, subtitle: item.job, image: item.image, go }
      })
    })

    store.dispatch('fetchCompanies', { first: 5, after: "", before: "", searchFilter: "", sortBy: "", name: "", level: "", type: "" })
    const companies = computed(() => {
      return store.getters.getTableFormattedCompanies.map(item => {
        const go = () => router.push({ name: 'Company', params: { id: item.id } })
        return { title: item.name, subtitle: item.country, image: item.image, go }
      })
    })

    store.dispatch('fetchProductions', { first: 5, after: "", before: "", searchFilter: "", sortBy: ""})
    const productions = computed(() => {
      // TODO: Not sure if 'item.workType.name' is the correct thing
      // to put into subtitle
      return store.getters.getTableFormattedProductions.map(item => {
        const go = () => router.push({ name: 'Production', params: { id: item.id } })
        return { title: item.name, image:(item.image) ? item.image : 'https://s3.eu-west-1.amazonaws.com/showdeck.io/static/placeholders/venue_placeholder.png', go }
      })
    })

    return {
      people,
      places,
      companies,
      productions,
      temp,
      headerImage,
    }
  }
}
</script>
