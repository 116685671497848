<template>
    
    <div class="mx-7 my-7">
        <div class="max-w-5xl mx-auto rounded-md p-2 bg-white dark:bg-primaryDark dark:text-white">
            <div class="cursor-pointer inline-block" @click="router.push({ name: title})">
                <div class="flex flex-row items-center pl-2">
                    <div class="flex items-center justify-center">
                        <slot></slot>
                    </div>
                    <h2 class="text-xl m-2">{{ title }}</h2>
                </div>
                <div></div>
            </div>
            <div class="inline-block custom-min-height w-full relative">
                <div v-if="previewItems.length === 0" class="text-xl absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                    <h2 class="text-center">No Entries Found</h2>
                </div>
                <div v-else class="grid grid-flow-row sm:grid-rows-1 sm:grid-cols-4 md:grid-cols-5">
                    <PreviewRowItem v-for="previewItem, idx in filteredPreviewItems" :key="idx"
                        :previewItem="previewItem" :roundedImage="roundedImages" />
                </div>
            </div>
            <div class="flex justify-end">
                <button @click="router.push({ name: title })"
                    class="cursor-pointer m-2 px-4 py-2 text-sm font-medium relative inline-flex items-center rounded-md border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark">
                    See more
                </button>
            </div>
        </div>

    </div>
</template>

<script>
import { ref, computed } from "vue";
import PreviewRowItem from '@/components/widgets/previewRow/PreviewRowItem'
import {useRouter} from "vue-router";

const screenWidth = ref(0)

export default {
    name: 'PreviewRow',
    props: {
        title: String,
        previewItems: Array,
        roundedImages: Boolean,
    },
    components: {
        PreviewRowItem
    },
    setup(props) {
        const router = useRouter()
        return {
            router,
            screenWidth,
            filteredPreviewItems: computed(() => {
                // Skip everything past the 4th element if the screen size is between 640 and 768,
                // otherwise skip everything after the 5th element
                const isMiddleWidth = 640 <= screenWidth.value && screenWidth.value <= 768
                return props.previewItems.slice(0, isMiddleWidth ? 4 : 5)
            }),
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize)
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize() {
            screenWidth.value = window.innerWidth
        }
    }
}
</script>

<style scoped>
.custom-min-height {
    min-height: 7rem;
}
</style>
